import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './Helper/Helper_i18n';
import * as serviceWorker from './serviceWorker';

declare global {
  interface Window {
    settings: any; // Replace YourSettingsType with the actual type of your 'settings' object
    opera: any;
    MSStream: any;
  }
}


//PUBLIC DEVICE
const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  let is_iPad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
  if(is_iPad){
    return "iPad";
  }

  if ((/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
    return "iOS";
  }

  return "Desktop";
}

//PUBLIC VARIABLE
window.settings = {
  WebUrl: process.env.REACT_APP_WEB_URL,
  ApiUrl: process.env.REACT_APP_API_URL,
  AssetUrl: process.env.REACT_APP_API_ASSET_URL,
  SocketUrl:process.env.REACT_APP_SOCKET_URL,
  DeviceOS: getMobileOperatingSystem(),
  DefaultLang: process.env.REACT_APP_DEFAULT_LANG,
  //KEY
  apiToken: process.env.REACT_APP_API_TOKEN,
  xenditCreditCardKey: process.env.REACT_APP_XENDIT_CC_KEY,
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register()
