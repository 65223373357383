import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import EN from '../Assets/UIString/UIStringEN.json';
import ID from '../Assets/UIString/UIStringID.json';
import { IListLanguage } from '../Interface/ILanguage';

const languageMapping: any = {
  'en-US': 'en',
  'id-ID': 'id',
  // ...other mappings
};
const defaultLanguage = 'id'; // Set your default language here

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: localStorage.getItem('i18nextLng') || defaultLanguage,
    debug: false,
    resources: {
        en: EN,
        id: ID,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
});

export const GetAvailableLangValue = (LangObj: { [key: string]: any; }) => {
  const GetValue = LangObj["en"] || LangObj["id"] || "image"
  return GetValue.toUpperCase();
}

export const GetCurrentLang = () => {
    const hasLang = languageMapping[i18n.language] || i18n.language;
    //i18n.changeLanguage(hasLang);
    return hasLang;
}

export const GetAllResources = () => {
  const resourcesArray: any = Object.keys(i18n?.options?.resources || {});
  const newLangMap: IListLanguage[] = resourcesArray.map((val: any) =>{
      return { ID: val, Description: val === "id" ? "Bahasa Indonesia" : val === "en" ? "Bahasa Inggris" : ""}
  })
  return newLangMap
}

export default i18n;